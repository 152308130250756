<template>
  <div class="LoanApplicationManagement">
    <h3>贷款申请记录</h3>
    <!-- 根据条件搜索 -->
    <el-form
      class=""
      :model="form"
      label-position="right"
      label-width="80px"
      inline
      ref="form"
    >
      <el-form-item label="单号：">
        <el-input
          style="width: 190px"
          v-model="form.applyId"
          placeholder="单号"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="产品名称：">
        <el-input
          style="width: 190px"
          v-model="form.title"
          placeholder="产品名称"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="申请人地址：">
        <el-cascader
          ref="cascader"
          style="width: 190px"
          v-model="form.areaCode"
          :props="props"
          :options="regionOptions"
          :show-all-levels="false"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="申请人：">
        <el-input style="width: 190px" v-model="form.name" placeholder="申请人">
        </el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select v-model="form.status" style="width: 200px">
          <el-option
            v-for="(item, index) in types"
            :key="index"
            :value="item.value"
            :label="item.label"
          ></el-option
        ></el-select>
      </el-form-item>

      <div>
        <el-button class="query-btn" type="primary" @click="search"
          >查询</el-button
        >
        <el-button class="query-btn" type="primary" @click="BulkDownload"
          >批量下载资料</el-button
        >
        <el-button class="query-btn" type="primary" @click="exportExcel"
          >导出申请记录</el-button
        >
        <el-button class="query-btn" type="primary" @click="clear"
          >清空</el-button
        >
      </div>
    </el-form>
    <!-- 申请记录公司详情 -->
    <ShowDetail v-if="showDetail" @closeDialog="closeDialog" ref="showDetail" />
    <!-- 申请记录农户详情 -->
    <ShowDetail2
      v-if="showDetail2"
      @closeDialog2="closeDialog2"
      ref="showDetail2"
    />
    <!-- 申请记录审批 -->
    <ShowEdit v-if="showEdit" @closeDialog3="closeDialog3" ref="showEdit" />
    <!-- 金融产品表格 -->
    <div class="table-content">
      <table-page
        :data="tableData"
        :columns="columns"
        :currentPage="query.pnum"
        :pageSize="query.psize"
        :total="total"
        :loading="loading"
        :border="true"
        @sizeChange="handleSizeChange"
        @currentChange="handleCurrentChange"
        @selectionChange="handleSelection"
      >
        <template slot="status" slot-scope="scope">
          <span v-if="scope.row.status == '-1'">驳回</span>
          <span v-if="scope.row.status == '0'">草稿</span>
          <span v-if="scope.row.status == '1'">审批中</span>
          <span v-if="scope.row.status == '2'">通过</span>
        </template>
        <template slot="createTime" slot-scope="scope">
          <span>{{
            scope.row.createTime ? scope.row.createTime.substr(0, 10) : ""
          }}</span>
        </template>
        <template slot="Time" slot-scope="scope">
          <span
            >{{
              scope.row.startDate ? scope.row.startDate.substr(0, 10) : ""
            }}~{{
              scope.row.endDate ? scope.row.endDate.substr(0, 10) : ""
            }}</span
          >
        </template>
        <template slot="farmerName" slot-scope="scope">
          {{ scope.row.farmerName }}
        </template>
        <template slot="operation" slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="detailClick(scope.row.applyId, scope.row.userType,scope.row.identityId)"
            >查看</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="editClick(scope.row.applyId, scope.row.status)"
            >审批</el-button
          >
          <el-button type="text" size="small" @click="Download(scope.row)"
            >下载</el-button
          >
        </template>
      </table-page>
    </div>
  </div>
</template>

<script>
import TablePage from "@/components/table/table-page";
import ShowEdit from "./ShowEdit";
import ShowDetail from "./ApplicationRecordDetails";
import ShowDetail2 from "./FarmerApplicationRecordDetails";
import { mapGetters } from "vuex";
import { batchDownload, applyPackage } from "@/api/RuralFinance";

import { wytgColumn } from "./tableColumn";
import {
  ApplicationRecordList,
  exportExcel,
  AreaTree,
} from "@/api/RuralFinance";

export default {
  data() {
    return {
      // Query: Query,
      tableData: [],
      columns: wytgColumn,
      loading: false,
      showEdit: false,
      showDetail: false,
      showDetail2: false,
      keyForm: 1,
      total: 0,
      query: {
        pnum: 1,
        psize: 10,
      },
      types: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "驳回",
          value: "-1",
        },
        {
          label: "草稿",
          value: "0",
        },
        {
          label: "审批中",
          value: "1",
        },
        {
          label: "通过",
          value: "2",
        },
      ],
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        fullPathName: "fullPathName",
        checkStrictly: true,
        emitPath: false,
      },
      form: {
        applyId: "",
        title: "",
        name: "",
        areaCode: "",
        status: "",
      },
      regionOptions: [], //地区
    };
  },
  computed: {
    ...mapGetters(["identityObj", "topSelectAreacode"]),
  },
  components: {
    // FormQuery,
    TablePage,
    ShowEdit,
    ShowDetail,
    ShowDetail2,
  },

  mounted() {
    this.getList();
    this.AreaTree();
  },

  methods: {
    //银行人员表格
    async getList() {
      let departId = JSON.parse(localStorage.getItem("userInfo")).content
        .deptId;
      let params = {
        ...this.query,
        departId: departId,
        areaCode: this.topSelectAreacode,
      };
      let res = await ApplicationRecordList(params);
      this.tableData = res.data;
      if (!res.code) {
        this.total = res.dataNum;
        this.loading = false;
      } else {
        this.$message.error("查询失败");
        this.loading = false;
      }
      this.tableData = res.data;
      this.total = res.dataNum;
    },
    //点击查看详情
    detailClick(applyId, userType,identityId) {
      if (userType == 2) {
        this.showDetail2 = true;
        console.log("查看公司详情");

        this.$nextTick(() => {
          this.$refs.showDetail2.init(applyId,identityId);
        });
      } else {
        this.showDetail = true;
        this.$nextTick(() => {
          this.$refs.showDetail.init(applyId,identityId);
        });
      }
    },
    //点击编辑按钮
    editClick(applyId, status) {
      this.showEdit = true;
      this.$nextTick(() => {
        this.$refs.showEdit.init(applyId, status);
      });
    },

    //所属区域
    async AreaTree() {
      let params = {
        parentCode: this.identityObj.areaCode,
      };
      let res = await AreaTree(params);
      this.regionOptions = res.data;
    },
    //导出excel表格
    async Download(item) {
      console.log(item, "查看");
      let params = {
        // name: this.form.name,
        // status: this.form.status,
        // approveStartTime: this.startTime,
        // approveEndTime: this.endTime,
        // departName: this.form.departName,
        // title: this.form.title,
        // areaCode: this.form.areaCode,
        // personDivision: this.identityObj.areaCode,
           applyId:item.applyId

      };
      let res = await applyPackage(params);
      if (res) {
        let blob = res;
        let userName = item.name;
        let title = item.title;
        const fileName = userName + "-" + title + ".zip";
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        let href = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/zip" })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },

    // 关闭公司详情弹框
    closeDialog() {
      this.showDetail = false;
    },
    // 关闭农户详情弹框
    closeDialog2() {
      this.showDetail2 = false;
    },
    // 关闭修改弹框
    closeDialog3() {
      this.showEdit = false;
      this.getList();
    },
    handleSelection(selects) {
      console.log(selects);
    },
    //分页单页切换
    handleCurrentChange(page) {
      this.query.pnum = page;
      this.getList();
    },
    //分页总页数切换
    handleSizeChange(size) {
      this.query = {
        pnum: 1,
        psize: size,
      };
      this.getList();
    },
    //导出excel表格
    async exportExcel() {
      console.log("导出excel表格");
      // applyId: "",
      //   title: "",
      let params = {
        name: this.form.name,
        status: this.form.status,
        approveStartTime: this.startTime,
        approveEndTime: this.endTime,
        departName: this.form.departName,
        title: this.form.title,
        areaCode: this.form.areaCode,
        personDivision: this.identityObj.areaCode,
      };
      let res = await exportExcel(params);
      if (res) {
        let blob = res;
        const fileName = "申请记录统计.xlsx";
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        let href = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/pdf" })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
    // 点击搜索按钮
   async search() {
      this.query.pnum = 1;
      // 组装条件
    let params={
      ...this.query,
      areaCode:this.form.areaCode,
      applyId:this.form.applyId,
      title:this.form.title,
      name:this.form.name,
      status:this.form.status,
    }
      let res = await ApplicationRecordList(params);
      this.tableData = res.data;
      if (!res.code) {
        this.total = res.dataNum;
        this.loading = false;
      } else {
        this.$message.error("查询失败");
        this.loading = false;
      }
      this.tableData = res.data;
      this.total = res.dataNum;
    },

    submit() {},
    // 搜索条件重置按钮
    clear() {
      this.query = {
        pnum: 1,
        psize: 10,
      };
      this.getList();
    },
    //批量下载
    async BulkDownload() {
      let params = {
        ...this.form,
        // ...this.query,
        createStartTime: this.startTime,
        createEndTime: this.endTime,
      };
      let res = await batchDownload(params);
      if (res) {
        let blob = res;
        const fileName = "批量下载文件.zip";
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        let href = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/zip" })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-form--inline {
  display: flex;
  flex-wrap: wrap;
}
/deep/.el-form-item {
  width: 25% !important;

  .el-input {
    width: 200px;
  }
  .el-form-item__label {
    width: 100px !important;
  }
  .el-form-item__content {
    width: 200px;
  }
  .el-date-editor {
    width: 200px;
  }
}
</style>
