export const wytgColumn = [
  {
    prop: "applyId",
    label: "申请单号",
    width: 100,
  },
  {
    prop: "title",
    label: "产品名称",
  },

  {
    prop: "money",
    label: "申请额度",
  },
  {
    prop: "applyTime",
    label: "提交时间",
  },
  {
    prop: "name",
    label: "申请人",
    width: 80,
  },
  {
    prop: "area",
    label: "地址",
    width: 140,
  },
  {
    prop: "status",
    label: "审批状态",
    width: 140,
    template: true,
  },
  {
    prop: "approveTime",
    label: "审批时间",
  },
  {
    prop: "approveMoney",
    label: "批准金额",
    width: 140,
  },

  {
    prop: "operation",
    label: "操作",
    width: 140,
    template: true,
  },
];
