<template>
  <el-dialog
    title="申请记录"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="1000px"
    height="1000px"
  >
    <!-- 内层弹窗的内容 -->
    <el-dialog
      width="60%"
      title="兴村通平台业务记录"
      :visible.sync="innerVisible"
      append-to-body
      custom-class="dialogTab"
    >
      <!-- 业务记录 -->
      <div class="BusinessRecord">
        <!-- tabs切换 -->
        <div class="tabs">
          <div
            v-for="(item, index) in XingCunTongAllList"
            :key="index"
            @click="TabsBtn(item.flag)"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="content">
          <table
            border="1"
            v-for="(item, index) in XingCunTongAllList"
            :key="index"
          >
            <!-- 生产服务 -->
            <thead>
              <tr
                v-if="
                  item.flag == 'Production' &&
                  BusinessRecordOptionsIndex == 'Production'
                "
              >
                <th>接管编号</th>
                <th>区域</th>
                <th>接管类型</th>
                <th>价格</th>
                <th>托管人</th>
                <th>托管时间</th>
                <th>接管状态</th>
              </tr>
            </thead>
            <tbody
              v-if="
                item.flag == 'Production' &&
                BusinessRecordOptionsIndex == 'Production'
              "
            >
              <tr v-for="(item, index) in item.ServiceList" :key="index">
                <td>{{ item.orderCode }}</td>
                <td>{{ item.areaName }}</td>
                <td>{{ item.orderType == "1" ? "全程托管" : "环节托管" }}</td>
                <td>{{ item.estimatePrice }}元/亩</td>
                <td>{{ item.farmerName }}</td>
                <td>{{ item.startDate }}-{{ item.endDate }}</td>
                <td>
                  {{
                    item.orderState == "00"
                      ? "待成交"
                      : item.orderState == "01"
                      ? "托管中"
                      : "托管结束"
                  }}
                </td>
              </tr>
            </tbody>
            <!-- 资源资产 -->
            <thead>
              <tr
                v-if="
                  item.flag == 'Resource' &&
                  BusinessRecordOptionsIndex == 'Resource'
                "
              >
                <th>托管编号</th>
                <th>项目名称</th>
                <th>交易方式</th>
                <th>成交时间</th>
                <th>成交价</th>
              </tr>
            </thead>
            <tbody
              v-if="
                item.flag == 'Resource' &&
                BusinessRecordOptionsIndex == 'Resource'
              "
            >
              <tr v-for="(item, index) in item.ServiceList" :key="index">
                <td>
                  {{ item.xmbh }}
                </td>
                <td>{{ item.xmmc }}</td>
                <td>{{ item.jyfs }}</td>
                <td>{{ item.jjjzsj }}</td>
                <td>{{ item.myBj }}元</td>
              </tr>
            </tbody>
            <!-- 免费记账 -->
            <thead>
              <tr
                v-if="
                  item.flag == 'Bookkeeping' &&
                  BusinessRecordOptionsIndex == 'Bookkeeping'
                "
              >
                <th>标题</th>
                <th>文件</th>
              </tr>
            </thead>
            <tbody
              v-if="
                item.flag == 'Bookkeeping' &&
                BusinessRecordOptionsIndex == 'Bookkeeping'
              "
            >
              <tr v-for="(item, index) in item.ServiceList" :key="index">
                <td>{{ item.title }}</td>
                <td class="col">{{ JSON.parse(item.files)[0].FilesName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </el-dialog>
    <div class="LoanDetails">
      <table border="1" cellpadding="0">

        <tr>
          <td>主体名称</td>
          <td>{{historyList. name }}</td>
          <td>申请产品</td>
          <td>{{ historyList.title }}</td>
        </tr>
        <tbody>
          <tr>
            <td>主体类型</td>
            <td>{{ typesName }}</td>
            <td>统一社会信用代码</td>
            <td>{{ historyList.idCardNo }}</td>
          </tr>

          <tr>
            <td>法人姓名</td>
            <!-- 点击的时候请求内层弹窗的三个接口 -->
            <td>
              {{ historyList.legalPersonName
              }}<span @click="InnerBoxBtn()">兴村通业务记录</span>
            </td>
            <td>法人身份证号</td>
            <td>{{ historyList.legalPersonNo }}</td>
          </tr>

          <tr>
            <td>地址</td>
            <td colspan="3">
              {{ historyList.area }}
            </td>
          </tr>

          <tr>
            <td>代理人</td>
            <td>{{ historyList.agentName }}</td>
            <td>代理人身份证号</td>
            <td>{{ historyList.agentNo }}</td>
          </tr>
          <tr>
            <td>手机号</td>
            <td>{{ historyList.phone }}</td>
          </tr>

          <tr>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td>年经营收入</td>
            <td>{{ historyList.income }}</td>
            <td>实际经营年限</td>
            <td>{{ historyList.operatYear }}年</td>
          </tr>

          <tr>
            <td>经营内容</td>
            <td colspan="3">
              {{ historyList.operatIntorducation }}
            </td>
          </tr>

          <tr>
            <td colspan="4"></td>
          </tr>
          <tr>
            <td>贷款金额</td>
            <td colspan="3">{{ historyList.money }}</td>
          </tr>

          <tr>
            <td>资金用途</td>
            <td colspan="3">{{ historyList.use }}</td>
          </tr>

          <tr>
            <td colspan="4"></td>
          </tr>

          <tr>
            <td>审批状态</td>
            <td>{{ statusName }}</td>
            <td>申请时间</td>
            <td>{{historyList.applyTime}}</td>
          </tr>
        </tbody>
      </table>
      <!-- 营业执照 -->
      <div class="BusinessLicense">
        <span>营业执照</span>
        <div class="BusinessLicenseImg">
          <img :src="baseUrl + 'admin/file/get?ossFilePath=' + historyList.licenseImg" alt="" />
        </div>
      </div>
      <!-- 法人身份证 -->
      <div class="LegalPersonIDCard">
        <div class="Left">
          <p class="title"><span>法人身份证人面像</span></p>
          <img :src="baseUrl + 'admin/file/get?ossFilePath=' +  historyList.userCardFrontImg" alt="" />
        </div>
        <div class="Right">
          <p class="title"><span>法人身份证国徽像</span></p>
          <img :src="baseUrl + 'admin/file/get?ossFilePath=' + historyList.userCardBackImg" alt="" />
        </div>
      </div>
      <!-- 其他附件 -->
      <div class="OtherAccessories">
        <span class="title">其他附件</span>
        <div class="BusinessLicenseItem" v-if="historyList.files">
          <div
            class="Item"
            v-for="(item, index) in JSON.parse(historyList.files)"
            :key="index"
          >
            <el-image
              :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl"
              @click="AddFiles(item)"
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
              </div>
            </el-image>
            <span>
              {{ item.FilesName }}
            </span>
          </div>
        </div>
        <div class="BusinessLicenseItem" v-else>暂无相关数据</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  operateDetails,
  ProductionService,
  ResourceAssets,
  selectExport,
} from "@/api/RuralFinance";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      baseUrl: window.globalUrl.BASE_API,
      dialogVisible: true,
      userId: "",
      historyList: [],
      innerVisible: false,
      identityId: "", //查看用户的identityId
      BusinessRecordOptions: ["生产服务", "资源资产流转", "兴村通免费记账"],
      BusinessRecordOptionsIndex: 0, // 业务记录选项
      // ResourceAssetsList: [], //生产服务列表
      // ResourceAssetList: [], //资源资产列表
      // selectExportList: [], //免费记账列表
      //申请状态
      statusType: [
        {
          label: "驳回",
          value: "-1",
        },
        {
          label: "草稿",
          value: "0",
        },
        {
          label: "审批中",
          value: "1",
        },
        {
          label: "通过",
          value: "2",
        },
      ],
      //主体类型
      types: [
        {
          label: "集体经济组织",
          value: "3",
        },
        {
          label: "农业企业",
          value: "41",
        },
        {
          label: "家庭农场",
          value: "42",
        },
        {
          label: "合作社",
          value: "43",
        },
      ],
      obj: {}, //详情中的页码，数量，和表单
      //主体类型名字
      typesName: "",
      statusName: "",
      XingCunTongAllList: [], // 三大列表综合数据
    };
  },

  computed: {
    ...mapGetters(["identityObj"]),
  },

  methods: {
    handleClose(done) {
      this.$emit("closeDialog", false);
    },
    //回显详情数据
    async init(applyId, identityId) {
      this.identityId = identityId;
      let params = {
        applyId: applyId,
      };
      let res = await operateDetails(params);
      this.historyList = res.data;
      this.userId = res.data.userId;
      // console.log(this.historyList,'查看')
      let Value = this.historyList.userType;
      let Value2 = this.historyList.status;
      let label = "";
      let label2 = "";
      this.types.filter((item, index) => {
        if (Value == item.value) {
          return (label = item.label);
        }
      });
      this.typesName = label;
      this.statusType.filter((item, index) => {
        if (Value2 == item.value) {
          return (label2 = item.label);
        }
      });
      this.statusName = label2;
    },
    //打开内层弹窗
    InnerBoxBtn() {
      this.innerVisible = true;
      //生产服务列表
      this.XingCunTongAllList = [];
      new Promise((resolve, reject) => {
        resolve(this.ProductionService());
        resolve(this.ResourceAssets());
        resolve(this.selectExport());
      });
      this.BusinessRecordOptionsIndex = this.XingCunTongAllList;
      console.log(this.BusinessRecordOptionsIndex, "查看下标");
    },
    //生产服务列表
    async ProductionService() {
      let userType = this.historyList.userType; //userType
      let authId = this.identityId; //authId
      // console.log(this.historyList.userId,'查看userId2')
      let params = {
        userType,
        authId,
      };
      // console.log(params);
      let res = await ProductionService(params);
      if (res.data.length) {
        this.XingCunTongAllList.unshift({
          title: "生产服务",
          flag: "Production",
          ServiceList: res.data,
        });
        this.BusinessRecordOptionsIndex = this.XingCunTongAllList[0].flag;
        // console.log(this.XingCunTongAllList, "生产服务push");
      }
    },
    //资源资产列表
    async ResourceAssets() {
      let userId = this.historyList.userId; //authId
      let identityId = this.identityId;
      let params = {
        userId: userId,
        identityId: identityId,
        size: 1000,
        current: 1,
      };
      let res = await ResourceAssets(params);
      if (res.data.records.length) {
        this.XingCunTongAllList.unshift({
          title: "资源资产",
          flag: "Resource",
          ServiceList: res.data.records,
        });
        this.BusinessRecordOptionsIndex = this.XingCunTongAllList[0].flag;
        // console.log(this.XingCunTongAllList, "资源资产push");
      }
    },
    //免费记账
    async selectExport() {
      // let userType = this.historyList.userType; //userType
      let userId = this.historyList.userId; //authId
      let identityId = this.identityId;
      // console.log(identityId, "这是identityId");
      let params = {
        userId: userId,
        identityId: identityId,
      };
      let res = await selectExport(params);
      if (res.data.length) {
        this.XingCunTongAllList.unshift({
          title: "免费记账",
          flag: "Bookkeeping",
          ServiceList: res.data,
        });
        this.BusinessRecordOptionsIndex = this.XingCunTongAllList[0].flag;
        // console.log(this.XingCunTongAllList, "免费记账push");
      }
    },
    //内层弹窗切换
    async TabsBtn(index) {
      this.BusinessRecordOptionsIndex = index;
      // console.log(this.BusinessRecordOptionsIndex, "这是下标");
    },
    //附件下载功能
    async AddFiles(item) {
      let dom = document.createElement("a");
      dom.style.display = "none";
      dom.href = item.imgUrl;
      dom.setAttribute("download", item.FilesName);
      document.body.appendChild(dom);
      dom.click();
    },
  },
};
</script>

<style lang="less" scoped>
.LoanDetails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    tr {
      td {
        width: 80px;
        height: 30px;
        text-align: center;
        span {
          background: #169bd5;
          color: white;
          padding: 3px;
          border-radius: 5px;
        }
      }
    }
  }
  /*营业执照*/
  .BusinessLicense {
    width: 100%;
    height: 100px;
    display: flex;
    padding: 20px;

    span {
      font-size: 20px;
      font-weight: bold;
      margin-top: 20px;
    }
    .BusinessLicenseImg {
      margin-left: 160px;
      margin-top: 20px;
      img {
        width: 200px;
        height: 80px;
      }
    }
  }
  /*法人身份证*/
  .LegalPersonIDCard {
    width: 100%;
    display: flex;
    border: solid 1px #ccc;
    padding: 5px 0px;

    .Left {
      width: 50%;
      display: flex;
      justify-content: space-around;
      img {
        width: 200px;
        height: 100px;
      }
      p {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .title {
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        span {
          width: 100px;
          font-size: 20px;
          font-weight: bold;
          margin-top: 20px;
          margin-right: 100px;
        }
      }
    }
    .Right {
      width: 50%;
      display: flex;
      justify-content: space-around;
      img {
        width: 200px;
        height: 100px;
      }
      p {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .title {
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        span {
          width: 100px;
          font-size: 20px;
          font-weight: bold;
          margin-top: 20px;
          margin-right: 100px;
        }
      }
    }
  }
  /*其它附件*/
  .OtherAccessories {
    width: 100%;
    height: 100px;
    display: flex;
    .title {
      width: 100px;
      font-size: 20px;
      font-weight: bold;
      margin-top: 20px;
      margin-right: 100px;
    }
    .BusinessLicenseItem {
      width: 100%;
      display: flex;
      div {
        display: flex;
        flex-direction: column;
        margin: 0px 5px;
        i {
          font-size: 50px;
        }
      }
      .Item {
        width: 150px;
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;
        position: relative;
        padding: 10px;
        .el-image {
          width: 150px;
          height: 85px;
          background: #e6e3e3;
          img {
            width: 200px;
            height: 50px;
          }
          i {
            font-size: 50px;
            font-weight: bold;
            margin-top: 20px;
          }
        }
        span {
          margin-top: 5px;
        }
        .del {
          width: 20px;
          height: 20px;
          border: solid 1px black;
          border-radius: 50px;
          display: none;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 5000;
          text-align: center;
          line-height: 20px;
        }
      }
    }
  }
}
/*业务记录*/
.dialogTab {
  width: 100%;
  height: 100%;
  .el-dialog__body {
    width: 100%;
    height: 100%;
    .BusinessRecord {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .tabs {
        display: flex;
        justify-content: space-evenly;
        div {
          width: 45%;
          text-align: center;
        }
      }
      .content {
        width: 100%;
        margin-top: 20px;
        table {
          width: 100%;
          height: 100%;
          border-collapse: collapse;
          td {
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
